.container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px; /* Ajusté pour plus d'espace */
    box-shadow: 0 0 20px rgba(0,0,0,0.25);
    border-radius: 10px;
    overflow: hidden;
    background: #f7f7f7;
    z-index: 99999;
}

.form-container {
    padding: 25px;
    text-align: center;
}

.social-container {
    margin: 20px 0;
}

.social-logo {
    width: 50px;
    height: auto;
    margin: 0 10px;
    cursor: pointer;
    display: inline-block;
}

input[type="email"], input[type="password"], input[type="text"] {
    width: calc(100% - 20px);
    padding: 10px;
    margin-top: 3%;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
}

.button {
    width: calc(100% - 20px);
    padding: 10px;
    margin-top: 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #0056b3;
}

.switch-button {
    margin-top: 10px;
    background-color: #28a745;
}

.switch-button:hover {
    background-color: #218838;
}
