.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .review-panel-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35%; /* Ajusté pour plus d'espace */
    box-shadow: 0 0 20px rgba(0,0,0,0.25);
    border-radius: 10px;
    overflow-y: auto;
    background: #f7f7f7;
    padding: 20px; /* Ajout de padding pour un espacement intérieur */
  }

  .close-button-new {
    position: absolute;
    top: 0px;  
    left: 0px;  
    color: black;
    background-color: #f7f7f7;
    border: none;  
    cursor: pointer;  /* Cursor pointer to indicate it's clickable */
    padding: 0px;  /* Sufficient padding for easy click */
    font-size: 24px;  /* Large enough for easy interaction */
    line-height: 1;  /* Adjust line height to ensure the text or icon is centered */
    border-radius: 50%;  /* Circular button */
    width: 32px;  /* Fixed width */
    height: 32px;  /* Fixed height */
    display: flex;  /* Using flex to center the content */
    align-items: center;  /* Center content vertically */
    justify-content: center;  /* Center content horizontally */
  }
  
  .close-button-new:hover {
    color: #f44336;
  }

.review-panel-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.review-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rating-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alignement des étoiles à gauche */
  margin-bottom: 20px;
  margin-top: 10px;
}

.rating-section h3 {
  margin-bottom: 0; /* Enlever la marge inférieure pour que les étoiles soient plus proches du titre */
}

.features-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 1%;
  margin-top: 1%; 
}

.info-file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-file-label {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.info-file-label:hover {
  background-color: #0056b3;
}

.info-file-input {
  display: none;
}

.upload-feedback {
  color: #555;
  font-size: 14px;
  text-align: center;
}

.uploaded-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centrer les images */
}

.uploaded-image-preview {
  width: 50px; /* Ajustement de la taille de la miniature */
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
}

.info-save-button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: center;
}

.info-save-button:hover {
  background-color: #0056b3;
}

.spinner{
    margin-top: 0px;
    margin-bottom: 0px;
}