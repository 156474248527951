@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');


@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(-50%) rotate(0deg);
    }
    100% {
        opacity: 1;
        transform: translateX(-50%) rotate(180deg);
    }
}



.overlay_step1 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 10; /* Assurez-vous que cela soit en dessous de votre flèche mais au-dessus des autres UI */
}

.overlay_step3 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 150; /* Assurez-vous que cela soit en dessous de votre flèche mais au-dessus des autres UI */
}

.instruction-container {
    position: absolute;
    height: 500px; /* Augmentez la hauteur si nécessaire */
    display: flex;
    top: 0%; /* Ajustez selon l'emplacement exact de votre barre de recherche */
    width:100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 105; /* Assurez-vous que c'est supérieur à l'overlay */
  }

  .instruction-container-step2 {
    position: fixed;
    height: 50px; 
    display: flex;
    top: 10%; 
    width:100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 105; 
  }

  .instruction-container-step3 {
    position: absolute;
    height: 50px; 
    display: flex;
    top: 10%;
    width:100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 105; 
  }
  
  .text {
    color: white;
    background: linear-gradient(145deg, rgba(0, 0, 0, 0.8), rgba(23, 23, 23, 0.8));
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    width: 280px; /* Ajustez selon le contenu */
    transition: all 0.3s ease-in-out;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s ease forwards 0.2s;
    margin-top: 100px;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
  
  .arrow {
    animation: slideIn 1s ease forwards;
    pointer-events: none;
    background-image: url('../../../assets/arrow.png');
    background-size: cover;
    height: 100px;
    width: 100px;
    margin-right: 40%;
    margin-top: 5%;
    transform: scaleX(-1);
   
}

.no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }
  
  


  