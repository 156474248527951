/* Nom unique pour le conteneur */
.add-new-place-owner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px; /* Ajusté pour plus d'espace */
    box-shadow: 0 0 20px rgba(113, 2, 2, 0.25);
    border-radius: 10px;
    border: #b22222;
    overflow: hidden;
    background: #c50a0a71;
    z-index: 99999;
}

/* Conteneur du formulaire */
.add-new-place-owner-form-container {
    padding: 25px;
    text-align: center;
}

/* Conteneur pour les icônes sociales */
.add-new-place-owner-social-container {
    margin: 20px 0;
}

/* Style pour les logos sociaux */
.add-new-place-owner-social-logo {
    width: 50px;
    height: auto;
    margin: 0 10px;
    cursor: pointer;
    display: inline-block;
}

/* Style pour les champs de saisie */
.add-new-place-owner-input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
}

/* Style pour le bouton */
.add-new-place-owner-button {
    width: calc(100% - 20px);
    padding: 10px;
    margin-top: 20px;
    background-color: #d4af37; /* Or */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
}

/* Effet au survol du bouton */
.add-new-place-owner-button:hover {
    background-color: #b8860b; /* Or plus foncé */
}



.info-save-button-owner {
    background-color: #c91919; /* Bleu vif */
    color: white;
    font-size: 16px; 
    border: none; /* Aucune bordure pour un look plus propre */
    padding: 10px 20px; /* Padding généreux pour un clic facile */
    border-radius: 4px; /* Coins légèrement arrondis pour un look moderne */
    cursor: pointer;
    width: 100%; 
    margin-bottom: 30px;
    margin-top: 30px;
  
  }
  .review-panel-container-owner {
    background-color: #fff5f5;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  .review-panel-title-owner {
    color: #b22222;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }

  .review-panel-restaurant-owner {
    color: goldenrod;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .add-new-place-owner-welcome{
    text-align: center;
    font-weight: bold;
    color: goldenrod;
    
    
  }
  .info-file-label-owner {
    display: block;
    text-align: center;
    font-weight: bold;
    color: #b22222;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .info-file-input-owner {
    display: none;
  }
  
  .upload-feedback-owner {
    color: #b22222;
    margin-top: 10px;
  }
  
  .uploaded-images-owner {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .uploaded-image-preview-owner {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
    border: 2px solid #b22222;
  }
  
  .review-section-owner {
    margin-bottom: 20px;
  }
  
  .comment-box-owner {
    margin-bottom: 20px;
  }
  
  .text-field-owner {
    background-color: #fff;
    border-radius: 8px;
  }
  
  .info-save-button-owner {
    background-color: #b22222;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .info-save-button-owner:hover {
    background-color: #8b0000;
  }
  
  .error-message-owner {
    color: red;
    margin-top: 10px;
  }
  
  .extras-toggle-owner {
    color: #b22222;
    cursor: pointer;
    margin-top: 20px;
    font-weight: bold;
  }
  
  .features-section-owner {
    background-color: #ffe6e6;
    padding: 10px;
    border-radius: 8px;
    margin-top: 10px;
  }
  
  .features-section-checkbox-owner {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .checkbox-owner {
    color: #b22222;
  }
  
  .explanation-container-owner {
    position: fixed; /* Fixes the position relative to the viewport */
    top: 50%; /* Centers the container vertically */
    left: 50%; /* Centers the container horizontally */
    transform: translate(-50%, -50%); /* Adjusts the container to be fully centered */
    width: 60%; /* Sets the width to 60% of the viewport (adjust as needed) */
    max-width: 800px; /* Ensures the container doesn't exceed a maximum width */
    background-color: #f9f9f9; /* Slightly off-white background for a softer appearance */
    z-index: 1000; /* Ensures the container appears above other elements */
    padding: 30px; /* Adds more padding inside the container */
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15); /* Adds a deeper shadow for a more pronounced effect */
    border-radius: 12px; /* Adds larger rounded corners */
    font-family: 'Arial', sans-serif; /* Professional and clean font */
    color: #333; /* Dark gray text for better readability */
}

.explanation-title-owner {
    font-size: 1.8em; /* Larger title font size */
    font-weight: bold;
    margin-bottom: 15px; /* Adds some space below the title */
    color: #b22222; /* Dark red color for the title to match the theme */
}
  
  /* Styles for the close button */
  .close-button-owner {
    position: absolute; /* Positions the button within the container */
    top: 10px; /* Positioning relative to the top of the container */
    right: 10px; /* Positioning relative to the right of the container */
    background: none; /* Removes default button styling */
    border: none; /* Removes border */
    font-size: 20px; /* Increases the font size of the cross */
    cursor: pointer; /* Changes cursor to pointer on hover */
  }