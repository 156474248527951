.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.review-panel-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw; 
  max-width: 400px; 
  height: 100vh; 
  max-height: 600px; 
  box-shadow: 0 0 20px rgba(0,0,0,0.25);
  border-radius: 10px;
  overflow-y: auto;
  background: #f7f7f7;
  padding: 20px; /* Ajout de padding pour un espacement intérieur */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  z-index: 300;
}

.close-button-new {
  position: absolute;
  top: 2px;  
  left: 2px;  
  color: black;
  background-color: transparent;
  border: none;  
  cursor: pointer;  /* Cursor pointer to indicate it's clickable */
  padding: 0px;  /* Sufficient padding for easy click */
  font-size: 24px;  /* Large enough for easy interaction */
  line-height: 1;  /* Adjust line height to ensure the text or icon is centered */
  border-radius: 50%;  /* Circular button */
  width: 32px;  /* Fixed width */
  height: 32px;  /* Fixed height */
  display: flex;  /* Using flex to center the content */
  align-items: center;  /* Center content vertically */
  justify-content: center;  /* Center content horizontally */
}

.close-button-new:hover {
  color: #f44336;
}

.review-panel-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
  margin-top: 10px;
  color: #333;
}

.review-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.comment-box {
  width: 100%;
}

.rating-section {
  text-align: left; /* Alignement du texte à gauche */
}

.rating-label {
  font-size: 1.2rem;
  font-weight: bold;
  display: block; /* Assure que la label est sur sa propre ligne */
}

.Rating {
  margin-top: 10px; /* Espace au-dessus des étoiles */
  display: flex;
  justify-content: center; /* Centre les étoiles horizontalement dans leur conteneur */
  width: 100%; /* Permet un centrage complet */
}


.features-section {
  margin-top: 20px;
}

.features-section-checkbox {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 2fr)); /* Crée des colonnes qui s'adaptent automatiquement */
  gap: 5px; /* Espacement réduit entre les checkboxes */
  margin-top: 20px; /* Espacement entre le titre et les checkboxes */
  justify-content: start; /* Alignement des colonnes sur la gauche */
}



.info-file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.info-file-label {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.info-file-label:hover {
  background-color: #0056b3;
}

.info-file-input {
  display: none;
}

.upload-feedback {
  color: #555;
  font-size: 14px;
  text-align: center;
}

.uploaded-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centrer les images */
}

.uploaded-image-preview {
  width: 50px; /* Ajustement de la taille de la miniature */
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
}

.info-save-button {
  background-color: #007BFF; /* Bleu vif */
  color: white;
  font-size: 16px; 
  border: none; /* Aucune bordure pour un look plus propre */
  padding: 10px 20px; /* Padding généreux pour un clic facile */
  border-radius: 4px; /* Coins légèrement arrondis pour un look moderne */
  cursor: pointer;
  width: 100%; 
  margin-bottom: 30px;
}

.modal-overlay {
  position: fixed;   /* Fixed positioning to cover the whole screen */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);  /* Semi-transparent background */
  display: flex;
  align-items: center;  /* Centers the content vertically */
  justify-content: center;  /* Centers the content horizontally */
  z-index: 1050;  /* Ensures the modal is on top of other content */
}

.modal-content {
  padding: 20px;
  background: white;  /* White background for the content area */
  border-radius: 8px;  /* Rounded corners for the modal */
  text-align: center;  /* Center text alignment for the content */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  /* Subtle shadow for some depth */
}

