/* RestaurantInfoWindow.css */
/* .info-window {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 0px;
  max-width: 100%;
  width:100%;
  box-sizing: border-box;
  display: block;
} */

.main-content-window {
  width: 95%;          /* Set the width to 95% of its parent element */
  height: 100%;        /* Height can adjust based on content, or set a specific height if needed */
  overflow-y: auto;    /* Enable vertical scrolling */
  overflow-x: hidden;  /* Hide horizontal scroll bar */
  padding: 10px;       /* Optional: Add padding inside the container */
  margin: 0 auto;      /* Center the container horizontally */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}


.close-button-new {
  position: absolute;
  top: 0px;  
  left: 0px;    
  border: none;  
  cursor: pointer;  /* Cursor pointer to indicate it's clickable */
  width: 32px;  /* Fixed width */
  height: 32px;  /* Fixed height */
  display: flex;  /* Using flex to center the content */
  align-items: center;  /* Center content vertically */
  justify-content: center;  /* Center content horizontally */
}

.close-button-new:hover {
  color: #f44336;
}



/* Spinner CSS */
.spinner {
  position: fixed;
  border: 8px solid #f3f3f3; 
  border-top: 8px solid #3498db; 
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  top: 40%;
  left: 15%;
  transition: left 0.3s ease-in-out,
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}




/* Style pour le groupe de boutons */
.button-group {
  display: flex;
  justify-content: space-between; /* Espace entre les boutons pour qu'ils remplissent tout l'espace */
  overflow-x: hidden;
  margin-top: 10px;
  margin-left: 5px;
  width: calc(100% - 10px); /* Pour qu'il ne dépasse pas du container */
}

/* Style pour chaque bouton avec icône */
.icon-button {
  display: flex;
  align-items: center;
  justify-content: center; /* Centre le contenu du bouton */
  background-color: #f4f4f4; /* Couleur de fond des boutons */
  color: #333; /* Couleur du texte */
  border: 2px solid #ccc; /* Contour des boutons */
  padding: 5px 10px;
  border-radius: 20px; /* Bords ovales */
  font-size: 12px;
  text-decoration: none; /* Pour le lien Google Maps */
  cursor: pointer;
  white-space: nowrap; /* Empêche le texte de passer à la ligne */
  flex-grow: 1; /* Le bouton prend tout l'espace disponible */
  max-width: 100%; /* Empêche les boutons de dépasser */
  margin-right: 8px; /* Espace entre les boutons */
}

.icon-button span {
  margin-left: 5px; /* Espace entre l'icône et le texte */
}

.icon-button:hover {
  background-color: #e2e2e2; /* Changement de couleur au survol */
}

.icon-button.active {
  background-color: #007BFF; /* Couleur de fond pour l'onglet actif */
  color: #FFFFFF; /* Couleur du texte pour l'onglet actif */
}

.icon-button.delete {
  background-color: #FF4136; /* Couleur spécifique pour le bouton supprimer */
  color: #FFFFFF;
}




.content-desktop {
  width: 70vw; /* Adjusted width for desktop */


  top: calc(70px + 75px); /* Adjust as necessary for desktop */
  bottom: 0;
  left: 15vw; /* Centers the content horizontally */
  right: 15vw;
  transition: transform 0.2s ease-out;
  
  width: 100%; /* Ensure it takes up 100% of the parent's width */
  height: 100%; /* Ensure it takes up 100% of the parent's height */
  box-sizing: border-box; /* Include padding and border in the element's dimensions */
}



.image-gallery-desktop {
  display: block;
  gap: 20px; /* Increase gap size for desktop */
}

.gallery-item-desktop {
  border: none;
  border-bottom: 3px solid #ddd;
  border-radius: 0;
  overflow: hidden;
  margin-bottom: 20px; /* Increase margin for better spacing */
  width: 100%; /* Ensure it takes up 100% of the parent's width */
  height: 100%; /* Ensure it takes up 100% of the parent's height */
  box-sizing: border-box; /* Include padding and border in the element's dimensions */
}


.gallery-large-small-container-desktop {
  display: flex;
  gap: 20px;
}

.gallery-image-large-desktop {
  width: 60%; /* Adjust width for desktop */
  height: 15vw; /* Adjust height for desktop */
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25); /* Slightly larger shadow for desktop */
  cursor: pointer;
}

.gallery-image-full-desktop {
  width: 100%;
  height: 15vw; /* Adjust height for desktop */
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.gallery-image-small-container-desktop {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Larger gap for desktop */
  width: 40%; /* Adjust width for desktop */
  position: relative;
}

.gallery-image-small-desktop {
  width: 100%;
  height: calc((15vw - 20px) / 2); /* Adjust height for desktop */
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  position: relative;
}

.overlay-more-desktop {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  height: calc((15vw - 50px) / 2); /* Adjust height for desktop */
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.75rem; /* Adjusted for better visibility on desktop */
}

.comment-section-desktop {
  padding: 20px 25px; /* Larger padding for desktop */
  font-size: 1.1rem; /* Adjusted font size */
  color: #333;
  text-align: left;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.owner-comment-desktop {
  border: 4px solid gold;
  background-color: rgba(255, 217, 0, 0.35);
  padding: 15px;
  border-radius: 8px;
}

.owner-comment-label-desktop {
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
}

.comment-author-desktop {
  display: flex;
  justify-content: flex-start;
  font-size: 1rem;
}

.author-name-desktop {
  font-family: 'Dancing Script', cursive;
  font-style: italic;
  color: #66325b;
  font-size: 1.5rem;
  margin-left: auto;
  display: block;
}

.posted-date-desktop {
  margin-left: auto;
}

.comment-date-desktop {
  color: #999;
  margin-left: auto;
}

.show-more-desktop {
  color: #007BFF;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 15px;
  font-size: 1rem;
}

.show-more-desktop:hover {
  text-decoration: underline;
}



.separator {
  height: 1px;
  background-color: #eee;
  margin: 10px 0;
}

.modal-content-desktop {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  z-index: 1050;
  overflow: hidden;
}

.modal-image-desktop {
  width: 100%;
  height: auto;
  max-height: 60vh;
  border-radius: 8px;
}

.modal-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #c90404;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 10px;
}

.closing-modal-button-desktop {
  position: absolute;
  top: 0%;
  left: 0%;
  background-color: rgba(255, 255, 255, 0);
  color: rgb(254, 0, 0);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1051;
}



.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}




.delete-button {
  padding: 10px;
  background-color: #FF0000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #cc0000;
}

.info-address,
.info-phone,
.info-price-range,
.info-website {
  margin-bottom: 10px;
  color: #555; /* Subtle text color for readability */
}

.info-address strong,
.info-phone strong,
.info-price-range strong,
.info-website strong {
  color: #000; /* Darker text for key info */
}

/* Opening Hours styling */
.info-opening-hours-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.2rem; /* Larger text for section titles */
  color: #007BFF; /* Highlight color */
}

.info-opening-hours {
  list-style: none;
  padding: 0;
}

.info-opening-hours li {
  background-color: #e8e8e8; /* Light background for each item */
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 5px;
}

.info-window-title {
  margin-top: 6%;
}

/* Hover effects for interactive elements */
/* .info-window a:hover {
  text-decoration: underline; 
} */