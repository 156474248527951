.spinner {
  position: fixed;
  top: 70%; /* Positionne le spinner à 70% de la hauteur de la fenêtre */
  left: 47.5%; /* Centre horizontalement */
  transform: translate(-50%, -50%); /* Centre parfaitement */
  width: 40px !important; /* Ajuste la taille du spinner */
  height: 40px !important; /* Ajuste la taille du spinner */
  border: 8px solid rgba(0, 0, 0, 0.1) !important; /* Couleur de la bordure */
  border-top: 8px solid #3498db !important; /* Couleur de la bordure supérieure */
  border-radius: 50% !important; /* Forme circulaire */
  animation: spin 2s linear infinite !important; /* Animation de rotation */
}

@keyframes spin {
  0% { 
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(360deg); 
  }
}

.main-content-window-desktop {
  width: 95%;          /* Set the width to 95% of its parent element */
  height: 100%;        /* Height can adjust based on content, or set a specific height if needed */
  overflow-y: auto;    /* Enable vertical scrolling */
  overflow-x: hidden;  /* Hide horizontal scroll bar */
  padding: 10px;       /* Optional: Add padding inside the container */
  margin: 0 auto;      /* Center the container horizontally */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  z-index: 200;
}

.button-group {
  display: flex;
  justify-content: space-between; /* Espace entre les boutons pour qu'ils remplissent tout l'espace */
  overflow-x: hidden;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 5px;
  width: calc(100% - 10px); /* Pour qu'il ne dépasse pas du container */
}

.button-group button {
  border: none;
  border-radius: 6px;

  cursor: pointer;
  flex-grow: 1;
  margin: 0 10px;
  padding: 5px 0px;
  font-size: 0.9rem;
  transition: background-color 0.3s, transform 0.2s;
}

.button-group button:hover {
  transform: translateY(-2px);
}

.button-group button.active {
  background-color: #232d74;
}

.add-button {
  background-color: #59a46a; /* Green color */
}

.add-button:hover {
  background-color: #218838;
}

.delete-button {
  background-color: #FF0000;
}

.delete-button:hover {
  background-color: #cc0000;
}

.content {
  width: 90vw;
  position: absolute;
  overflow-y: auto; /* Utilisez auto pour montrer la barre de défilement uniquement si nécessaire */
  top: calc(70px + 75px); /* Exemple: 70px est la hauteur estimée du .title-restau-container */
  bottom: 0;
  left: 5vw; /* Définit l'espacement à gauche à 5% */
  right: 5vw; /* Définit également l'espacement à droite à 5% */
  transition: transform 0.2s ease-out;
}

.image-gallery {
  display: block;
  gap: 10px;
}

.gallery-item {
  border: none; /* Enlève toutes les bordures */
  border-bottom: 3px solid #ddd; /* Ajoute une ligne en dessous */
  border-radius: 0; /* Assure que les coins ne sont pas arrondis */
  overflow: hidden;
  margin-bottom: 10px; 
}


.gallery-large-small-container {
  display: flex;
  gap: 10px;
}

.gallery-image-large {
  width: 70%;
  height: 50vw;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.25);
  cursor: pointer;
}

.gallery-image-full {
  width: 100%;
  height: 50vw;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.25);
  cursor: pointer;
}

.gallery-image-small-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 30%; 
  position: relative;
}

.gallery-image-small {
  width: 100%;
  height: calc((50vw - 10px) / 2); 
  object-fit: cover;
  border-radius: 10%; 
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0,0,0,0.3); 
  position: relative;
}

.overlay-more {
  position: absolute;
  bottom: 10px; /* Ajustez pour positionner l'overlay en bas */
  left: 0;
  width: 100%; /* Pour couvrir toute la largeur de la dernière image */
  height: calc((50vw - 10px) / 2); /* Pour couvrir toute la hauteur de la dernière image */
  background-color: rgba(0, 0, 0, 0.5); /* Couleur de fond semi-transparente */
  color: white; /* Couleur du texte */
  display: flex; /* Utilisez Flexbox pour centrer le texte */
  align-items: center; /* Centre verticalement le texte */
  justify-content: center; /* Centre horizontalement le texte */
  border-radius: 10%; /* Ajustez cette valeur pour correspondre au bord de l'image */
  cursor: pointer;
  font-size: 1.5rem; /* Taille du texte, ajustez selon vos besoins */
}


.comment-section {
  padding: 15px 20px;
  font-size: 1rem;
  color: #333;
  text-align: left;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.owner-comment {
  border: 4px solid gold;
  background-color: rgba(255, 217, 0, 0.353);
  padding: 10px;
  border-radius: 5px;
}

.owner-comment-label {
  font-weight: bold;
  color: rgb(0, 0, 0);
  margin-bottom: 5px;
}




.comment-author {
  display: flex;
  justify-content: flex-start; /* Align items to the start */
  font-size: 0.85rem;
}

.author-name {
  font-family: 'Dancing Script', cursive;
  font-style: italic !important;

  color: #66325b; 
  font-size: 1.25rem; /* Ajustez la taille selon vos besoins */
  margin-left: auto;
  display: block;

}

.posted-date {
  margin-left: auto; /* Push this item to the right */
}

.comment-date {
  color: #999;
  margin-left: auto; /* Push this item to the right */
}

.show-more {
  color: #007BFF; /* Blue color for the clickable text */
  cursor: pointer;
  display: inline-block;

  margin-bottom: 10px;
  font-size: 0.75rem;
}

.show-more:hover {
  text-decoration: underline; /* Underline on hover for better visibility */
}

.separator {
  height: 1px;
  background-color: #eee;
  margin: 10px 0;
}
.modal-content-new {
  background-color: rgba(250, 248, 248, 0.74); /* Fond transparent */
  border-radius: 12px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
  position: fixed;
  width: 100vw; /* Utilisez 100vw pour occuper toute la largeur de la fenêtre */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  max-height: 100vh; /* Hauteur maximum pour éviter de recouvrir entièrement l'écran */
  z-index: 1050;
  overflow: auto; /* Permet le défilement si le contenu est trop grand */
}

.closing-modal-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1051;
}

.closing-modal-button:hover {
  background-color: darkred;
}


.modal-image {
  width: 100%; /* Ajuste la largeur à 100% du conteneur parent, généralement le modal */
  max-height: 95vh; /* Limite la hauteur de l'image à 60% de la hauteur de la vue */
  object-fit: contain; /* Assure que tout le contenu de l'image est visible sans être rogné */
  display: block; /* Empêche tout espace blanc en dessous de l'image */
  margin: 0 auto; /* Centre l'image horizontalement dans le modal */
  border-radius: 10px; /* Coins arrondis pour adoucir les bords de l'image */
}

.comment-overlay {
  position: block;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.6); /* Dark semi-transparent background */
  padding: 10px; /* Padding for some space inside the overlay */
  box-sizing: border-box; /* Include padding in width calculations */

}


.comment-text-modal {
  font-size: 0.85rem;
  color: #000000;


}

.comment-text {
  font-size: 1rem;
  color: #111111;


}










.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.info-address,
.info-phone,
.info-price-range,
.info-website {
  margin-bottom: 10px;
  color: #555; /* Subtle text color for readability */
}

.info-address strong,
.info-phone strong,
.info-price-range strong,
.info-website strong {
  color: #000; /* Darker text for key info */
}

/* Opening Hours styling */
.info-opening-hours-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.2rem; /* Larger text for section titles */
  color: #007BFF; /* Highlight color */
}

.info-opening-hours {
  list-style: none;
  padding: 0;
}

.info-opening-hours li {
  background-color: #e8e8e8; /* Light background for each item */
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 5px;
}

.title-restau-container {
  position: absolute;
  top: 25px;
  left: 0; 
  width: 100%; 
  background-color: transparent;
  z-index: 1; 
  padding: 0px 0;
  user-select: text; /* Permet la sélection du texte */

}

.info-header h2 {
  white-space: nowrap;        /* Empêche le texte de passer à la ligne */
  overflow: hidden;           /* Cache le texte qui dépasse */
  text-overflow: ellipsis;    /* Ajoute "..." à la fin du texte s'il dépasse */
  margin-left: 5px;
}


.info-window-title, .google-maps-link {
  margin-left: 10px;
  margin-top: 1%;
}

.comment-overlay{
  margin-top: 1vh;
}