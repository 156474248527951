.main-content-window {
  width: 95%;          /* Set the width to 95% of its parent element */
  height: 100%;        /* Height can adjust based on content, or set a specific height if needed */
  overflow-y: auto;    /* Enable vertical scrolling */
  overflow-x: hidden;  /* Hide horizontal scroll bar */
  padding: 10px;       /* Optional: Add padding inside the container */
  margin: 0 auto;      /* Center the container horizontally */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  z-index: 200;
}

.close-button {
  position: absolute;
  top: 10px;  
  left: 10px;  
  background-color: white;  
  color: black;  
  border: none;  
  cursor: pointer;  /* Cursor pointer to indicate it's clickable */
  padding: 5px;  /* Sufficient padding for easy click */
  font-size: 24px;  /* Large enough for easy interaction */
  line-height: 1;  /* Adjust line height to ensure the text or icon is centered */
  border-radius: 50%;  /* Circular button */
  width: 32px;  /* Fixed width */
  height: 32px;  /* Fixed height */
  display: flex;  /* Using flex to center the content */
  align-items: center;  /* Center content vertically */
  justify-content: center;  /* Center content horizontally */
}

.close-button:hover {
  color: #f44336;
}

.info-name {
  text-align: center;
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 5%;
}

.photo-gallery {
  position: relative;
  display: flex;
  align-items: center;   /* Center the images vertically */
  justify-content: center; /* Center the images horizontally */
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;   /* Take the full width of its parent */
  height: auto;  /* Automatic height based on content */
  border-radius: 8px;
  overflow: hidden; /* Hide any overflow content */
}

.photo-gallery img {
  width: 100%;      /* Each image takes 100% of the container's width */
  height: 200px;    /* Fixed height for all images */
  object-fit: cover; /* Ensure images cover the content area without distorting aspect ratio */
  border-radius: 8px; /* Rounded corners for images */
}

.info-photo {
  max-width: 100%;
  max-height: 100%;
}

.gallery-button {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}

.gallery-button-left {
  left: 10px;
}

.gallery-button-right {
  right: 10px;
}

.info-address {
  font-size: 1rem;
  margin: 10px 0;
  color: #555;
}

.add-review-button-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  margin-top: 10px; 
}

.add-review-button {
  background-color: #ff6600; 
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 8px; 
  cursor: pointer;
  margin-top: 20px; 
  transition: background-color 0.3s, transform 0.3s;
  font-size: 1.2rem; 
  font-weight: bold; 
}

.token-button{
  background-color: #0091ff; 
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 8px; 
  cursor: pointer;
  margin-top: 20px; 
  transition: background-color 0.3s, transform 0.3s;
  font-size: 1.2rem; 
  font-weight: bold; 
}

.add-review-button:hover {
  background-color: #cc5200; /* Change color on hover */
}

.upload-feedback {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #333;
}

.uploaded-images {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.uploaded-image-preview {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}